:root {
  --mint: #b8d0d4;
  --bg-white: #f3f3f3;
  --font-family: 'Raleway', sans-serif;
}

body {
  margin: 0;
  background-image: url("/public/background.jpg");
  font-family: var(--font-family) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 18px;
  color: white;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.content {
  width: 800px;
  text-align: center;
}

.content .header {
  font-size: 50px;
}

.content .header .first-name {
  font-weight: 100;
}

.content .long-description {
  line-height: 1.5;
  padding: 20px;
}

.typewrite {
  margin-top: 20px;
  font-size: 20px;
}

.link {
  padding: 10px;
}

.link a {
  text-decoration: none;
  color: white;
}

.tech {
  font-weight: bolder;
}

svg:hover {
  transform: rotate(360deg);
  transition: all 0.3s ease-in-out 0s;
}

@media only screen and (max-width: 600px) {
  .content .header {
    font-size: 40px;
  }
}